import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from '../home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { GiftcardComponent } from '../giftcard/giftcard.component';
import { RegistredgiftcardComponent } from '../registredgiftcard/registredgiftcard.component';
import { GiftcardlistComponent } from '../giftcardlist/giftcardlist.component';
import { GiftcardtopupComponent } from '../giftcardtopup/giftcardtopup.component';
import { PaymentpreviewComponent } from '../paymentpreview/paymentpreview.component';
import { PaymenthistoryComponent } from '../paymenthistory/paymenthistory.component';
import { ProfileComponent } from '../profile/profile.component';
import { ProceedpaymentComponent } from '../proceedpayment/proceedpayment.component';
import { PaymentresponseComponent } from '../paymentresponse/paymentresponse.component';
import { PaymentDohaBankComponent } from '../payment-doha-bank/payment-doha-bank.component';
import { PaymentCyberSourceComponentComponent } from '../payment-cyber-source-component/payment-cyber-source-component.component';
import { SmartPayGatewayComponent } from '../smart-pay-gateway/smart-pay-gateway.component';
import { QpayGatewayComponent } from '../qpay-gateway/qpay-gateway.component';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { NoDataScreenComponent } from '../no-data-screen/no-data-screen.component';
import { StandingInstructionComponent } from '../standing-instruction/standing-instruction.component';
import { PayItComponent } from '../pay-it/pay-it.component';
import { KnetComponent } from '../knet/knet.component';
// import { PayitpaymentpreviewComponent } from '../payitpaymentpreview/payitpaymentpreview.component';
import {QpaypaymentpreviewComponent} from '../qpaypaymentpreview/qpaypaymentpreview.component'
const routes: Routes = [
        {path: '', redirectTo: 'home', pathMatch: 'full'},
        {path: '', component: HomeComponent},
        {path: 'home', component: HomeComponent},
        // {path: 'home/:req_param', component: HomeComponent},
        {path: 'online/:req_param', component: HomeComponent},
        {path: 'giftcard', component: GiftcardComponent},
        {path: 'giftcardregistered', component: RegistredgiftcardComponent},
        {path: 'giftcardlist', component: GiftcardlistComponent},
        {path: 'giftcardtopup', component: GiftcardtopupComponent},
        {path: 'paymentpreview', component: PaymentpreviewComponent},
        {path: 'paymenthistory', component: PaymenthistoryComponent},
        {path: 'profile', component: ProfileComponent},
        {path: 'proceedpayment', component: ProceedpaymentComponent},
        {path: 'paymentresponse', component: PaymentresponseComponent},
        {path: 'paymentdohabank', component: PaymentDohaBankComponent},
        {path: 'paymentcybersource', component: PaymentCyberSourceComponentComponent},
        {path: 'smartpay', component:SmartPayGatewayComponent },
        {path: 'qpay', component:QpayGatewayComponent},
        {path: 'terms_and_conditions', component:TermsAndConditionsComponent },
        {path: 'noregisteredcards', component: NoDataScreenComponent},
        {path: 'si_creation', component: StandingInstructionComponent},
        // {path: 'payit', component: PayItComponent},
        {path: 'knet', component: KnetComponent},
        // {path: 'paymentview', component: PayitpaymentpreviewComponent},
        {path:'qpaypaymentpreview',component:QpaypaymentpreviewComponent}
    ];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
            RouterModule
        ],
})
export class AppRoutingModule { }
