<app-kalyan-navbar></app-kalyan-navbar>
<div class="bg-default-1 pt-5">

  <div class="container" style="margin-top: auto;margin-left:auto;background: white">


    <mat-card>
      <form ngNoForm #nonseamless id="nonseamless" name="nonseamless" method="post" name="redirect"
        action="https://pg-api.qpay.gov.qa/qcb-pg/api/gateway/2.0">
        <div id="proceed_payment_div">
          <br />
          <br />
          <br /><br />
          <input type="hidden" name="Amount" id="amount" value="{{amount}}" />
          <input type="hidden" name="CurrencyCode" id="currencyCode" value="{{currencyCode}}" />
          <input type="hidden" name="PUN" id="pun" value="{{pun}}" />
          <input type="hidden" name="MerchantModuleSessionID" id="merchantModuleSessionID" value="{{merchantModuleSessionID}}" />
          <input type="hidden" name="PaymentDescription" id="paymentDescription" value="{{paymentDescription}}" />
          <input type="hidden" name="NationalID" id="nationalID" value="{{nationalID}}" />
          <input type="hidden" name="MerchantID" id="merchantID" value="{{merchantID}}" />
          <input type="hidden" name="BankID" id="bankID" value="{{bankID}}" />
          <input type="hidden" name="Lang" id="lang" value="{{lang}}" />
          <input type="hidden" name="Action" id="action" value="{{action}}" />
          <input type="hidden" name="SecureHash" id="secureHash" value="{{secureHash}}" />
          <input type="hidden" name="TransactionRequestDate" id="transactionRequestDate" value="{{transactionRequestDate}}" />
          <input type="hidden" name="ExtraFields_f14" id="extraFields_f14" value="{{extraFields_f14}}" />
          <input type="hidden" name="Quantity" id="quantity" value="{{quantity}}" />

      <button style="visibility:hidden">Submit</button>
      <p>Please wait while your transaction is being processed. You will be automatically redirected to the
        confirmation page.Please do not hit "back", "forward" or "refresh" button.</p>
      <div class="form-group text-center m-t-15">
        <div class="col-xs-12">
          <button type="submit" class="btn btn-sm btn-primary" style="display: none"
            style="color:white;background-color: red;align:center;visibility:hidden"
            id="confirm_payment">Confirm</button>
        </div>
      </div>
  </div>
  <br />
  </form>
  <script type="text/javascript">
    $('#nonseamless').submit()
  </script>

  </mat-card>
</div>
</div>