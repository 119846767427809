<!-- <ngx-spinner></ngx-spinner> -->
<div class="modal fade form-modal" id="tc-popup" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog max-width-px-840 position-relative">
    <!-- <button type="button" class="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10" data-dismiss="modal"><i class="fas fa-times"></i></button> -->
    <div class="login-modal-main bg-white rounded-8 overflow-hidden">
      <div class="row no-gutters">
        <div class="col-lg-12 col-md-6">
          <div class="bg-white-2 h-100 px-11 pt-11 pb-7">
            <div class="or-devider">
              <span class="font-size-3 line-height-reset "><img src="/assets/images/logo.png"></span>
            </div>
            <h6 class="text-uppercase font-weight-semibold">{{tcheader[0]}}</h6>
            <ul>
              <li *ngFor='let tc of tcgeneral'>{{tc}}</li>
            </ul>
            <h4 class="text-center" style="margin-top: 20px;">GIFT CARD RELOAD PROGRAM</h4>
            <p class="font-weight-semibold text-uppercase text-center">DETAILS OF THE GIFT CARD RELOAD PROGRAM ARE AS
              FOLLOWS (G.C.R.P)</p>

            <table class="table table-bordered text-center" *ngIf="country_code == '784'">
              <thead class="thead-light">
                <tr>

                  <th>MONTHS</th>
                  <th>NUMBER OF CARD RELOADS</th>
                  <th>GOLD </th>
                  <th>PRECIOUS/ UNCUT/POLKI</th>
                  <th>DIAMOND</th>
                </tr>
              </thead>
              <tr>
                <td>6 Months </td>
                <td>6 Times</td>
                <td> 40% </td>
                <td> 60% </td>
                <td> 80% </td>
              </tr>
              <tr>
                <td>9 Months </td>
                <td>9 Times </td>
                <td> 60% </td>
                <td> 90% </td>
                <td> 120% </td>
              </tr>
              <tr>
                <td> 12 Months </td>
                <td> 12 Times</td>
                <td> 100% </td>
                <td> 120% </td>
                <td> 160% </td>
              </tr>



            </table>
            <table class="table table-bordered text-center" *ngIf="country_code == '512'">
              <thead class="thead-light">
                <tr>

                  <th>MONTHS</th>
                  <th>NUMBER OF CARD RELOADS</th>
                  <th>GOLD </th>
                  <th>PRECIOUS/ UNCUT/POLKI</th>
                  <th>DIAMOND</th>
                </tr>
              </thead>
              <tr>
                <td>6 Months </td>
                <td>6 Times</td>
                <td> 40% </td>
                <td> 60% </td>
                <td> 80% </td>
              </tr>
              <tr>
                <td>9 Months </td>
                <td>9 Times </td>
                <td> 60% </td>
                <td> 90% </td>
                <td> 120% </td>
              </tr>
              <tr>
                <td> 12 Months </td>
                <td> 12 Times</td>
                <td> 100% </td>
                <td> 120% </td>
                <td> 160% </td>
              </tr>



            </table>
            <table class="table table-bordered text-center" *ngIf="country_code == '634'">
              <thead class="thead-light">
                <tr>

                  <th>MONTHS</th>
                  <th>NUMBER OF CARD RELOADS</th>
                  <th>GOLD </th>
                  <th>PRECIOUS/ UNCUT/POLKI</th>
                  <th>DIAMOND</th>
                </tr>
              </thead>
              <tr>
                <td>6 Months </td>
                <td>6 Times</td>
                <td> 40% </td>
                <td> 60% </td>
                <td> 80% </td>
              </tr>
              <tr>
                <td>9 Months </td>
                <td>9 Times </td>
                <td> 60% </td>
                <td> 90% </td>
                <td> 120% </td>
              </tr>
              <tr>
                <td> 12 Months </td>
                <td> 12 Times</td>
                <td> 80% </td>
                <td> 120% </td>
                <td> 160% </td>
              </tr>



            </table>

            <table class="table table-bordered text-center" *ngIf="country_code == '414'">
              <thead class="thead-light">
                <tr>

                  <th>MONTHS</th>
                  <th>NUMBER OF CARD RELOADS</th>
                  <th>GOLD </th>
                  <th>PRECIOUS/ UNCUT/POLKI</th>
                  <th>DIAMOND</th>
                </tr>
              </thead>
              <tr>
                <td>6 Months </td>
                <td>6 Times</td>
                <td> 40% </td>
                <td> 60% </td>
                <td> 80% </td>
              </tr>
              <tr>
                <td>9 Months </td>
                <td>9 Times </td>
                <td> 60% </td>
                <td> 90% </td>
                <td> 120% </td>
              </tr>
              <tr>
                <td> 12 Months </td>
                <td> 12 Times</td>
                <td> 80% </td>
                <td> 120% </td>
                <td> 160% </td>
              </tr>



            </table>

            <h6 class="text-uppercase font-weight-semibold">{{tcheader[1]}}</h6>
            <ul>
              <li *ngFor='let tc of tcbenefits'>{{tc}}</li>
            </ul>
            <button type="button" (click)="termandconclick()" [hidden]="tandcfoot" class="btn btn-danger">I
              Accept</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-kalyan-navbar></app-kalyan-navbar>
<br />
<br />
<br />

<!-- ================================ -->

<mat-card>
  <div class="bg-default-1 pt-10 pb-10 pb-lg-10">

    <div class="container" style="margin-left:auto;">


      <mat-card>
        <mat-tab-group>
          <mat-tab label="Giftcard Registration">
            <br>
            <form #formG="ngForm" (ngSubmit)="giftcardSubmit(formG.value)" enctype="multipart/form-data">
              <h6 class="font-weight-semibold text-uppercase">Gift card information</h6>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label">Gift Card</label><span class="text-danger ml-2"> *</span>
                    <select class="form-control fields-required" [(ngModel)]="newGiftcard.giftcardtype"
                      name="giftcardtype" required="true" autofocus="" data-attr="Gift Card Type">
                      <option value="" selected="" disabled="">--Select Gift Card Type--</option>
                      <option *ngFor="let scheme of schemes" [value]="scheme.scheme_id">
                        {{scheme.scheme_name}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">Monthly Reload Amount(Min. {{min_amount}} and Max.
                      {{max_amount}})</label><span class="text-danger ml-2"> *</span>
                    <input [(ngModel)]="newGiftcard.monthdenomamt" name="monthdenomamt" id="monthdenomamt" type="number"
                      required="true" (change)="setEmiAmount( $event )" class="form-control fields-required"
                      data-attr="EMI Amount">
                    <p class="warningofemi" style="font-size: 10px"></p>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <label class="control-label">Tenure</label><span class="text-danger ml-2"> *</span>
                    <select class="form-control fields-required" [(ngModel)]="tenureselected" name="tenureselected"
                      required="true" (change)="setcalctotamount( $event)" data-attr="Tenure">
                      <option *ngFor="let tenure of tenureselection" [value]="tenure.value">{{tenure.title}}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">Total Amount</label><span class="text-danger ml-2"> *</span>
                    <input [(ngModel)]="totalamt" [value]="tenureselected * newGiftcard.monthdenomamt " name="totalamt"
                      readonly="true" class="form-control fields-required" data-attr="Total Amount">
                  </div>
                </div>
              </div>
              <br />
              <h6 class="font-weight-semibold text-uppercase mt-5">Personal Details</h6>
              <hr>
              <div class="row mt-1">
                <div class="col-md-6">
                  <label class="control-label">First Name</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="newGiftcard.persfirstname" name="persfirstname" required="true"
                    [readonly]="isFirstnamecon" class="form-control fields-required" data-attr="First Name">
                </div>
                <div class="col-md-6">
                  <label class="control-label">Last Name</label>
                  <input [(ngModel)]="newGiftcard.perslastname" name="perslastname" class="form-control">
                </div>
              </div>
              <br />
              <div class="row mt-2">
                <div class="col-md-6">
                  <label class="control-label">Date Of Birth</label>
                  <input [(ngModel)]="newGiftcard.persdob" type="date" name="persdob" class="form-control">
                </div>
                <div class="col-md-6">
                  <label class="control-label">Anniversary</label>
                  <input class="form-control" type="date" name="anniversary">
                </div>
              </div>
              <br />
              <div class="row mt-2">

                <div class="col-md-6">
                  <label class="control-label">Mobile Number</label><span class="text-danger ml-2"> *</span>
                  <input [value]="loginmob" [(ngModel)]="loginmob" name="loginmob" readonly="true"
                    class="form-control fields-required" data-attr="Mobile Number">
                </div>
                <div class="col-md-6">
                  <label class="control-label">Email</label><span class="text-danger ml-2"> *</span>
                  <input [value]="loginemailid" [(ngModel)]="loginemailid" name="loginemailid"
                    class="form-control fields-required" readonly="true" data-attr="Email">
                </div>
              </div>

              <br />
              <div class="row mt-2">
                <div class="col-md-6">
                  <label class="control-label">Beneficiary</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="newGiftcard.persbeneficiary" required="true" name="persbeneficiary"
                    class="form-control fields-required" data-attr="Beneficiary">
                </div>
              </div>
              <br />
              <h6 class="font-weight-semibold text-uppercase mt-5">Current Address</h6>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">Address Line1</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="curstreet1" [value]="curstreet1" name="curstreet1"
                    class="form-control fields-required" data-attr="Current Address 1">
                </div>
                <div class="col-md-6">
                  <label class="control-label">Address Line2</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="curstreet2" [value]="curstreet2" name="curstreet2"
                    class="form-control fields-required" data-attr="Current Address 2">
                </div>
                <div class="col-md-6 mt-5" *ngIf="country_code == '784'">
                  <label class="control-label">Emirates</label><span class="text-danger ml-2"> *</span>
                  <select [(ngModel)]="curcity" name="curcity" class="form-control fields-required"
                    data-attr="Current City">
                    <option value="" selected="" disabled="">--Select Emirates--</option>
                    <option value="ABU DHABI">ABU DHABI</option>
                    <option value="DUBAI">DUBAI</option>
                    <option value="SHARJAH">SHARJAH</option>
                    <option value="AJMAN">AJMAN</option>
                    <option value="RAS AL KHAIMAH">RAS AL KHAIMAH</option>
                    <option value="FUJAIRAH">FUJAIRAH</option>
                    <option value="UMM AL QUWAIN">UMM AL QUWAIN</option>
                  </select>
                </div>
                <div class="col-md-6 mt-5"
                  *ngIf="country_code == '634' || country_code == '414' || country_code == '512'"
                  style="display: none;">
                  <label class="control-label">Emirates</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="curcity" name="curcity" class="form-control fields-required" value="{{curcity}}"
                    data-attr="Current Emirates">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">State</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="curstate" [value]="curstate" name="curstate" class="form-control fields-required"
                    data-attr="Current State">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">PO Box</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="curpobox" [value]="curpobox" name="curpobox" class="form-control fields-required"
                    data-attr="Current PO Box">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Country</label><span class="text-danger ml-2"> *</span>
                  <select [(ngModel)]="curcountry" name="curcountry" id="curcountry"
                    class="form-control fields-required" data-attr="Current Country">
                    <option value="{{country_name}}">{{country_name}}</option>
                  </select>
                </div>
              </div>
              <br />
              <h6 class="font-weight-semibold text-uppercase mt-5">Permanent Address</h6>
              <hr>
              <label class="switch">
                <input type="checkbox" id="percheck" [checked]="isSaveCurPermanent"
                  (input)="setSaveCurPermanent( $event )">
                <span class="slider round"></span>
              </label><span class=" ml-4">Same as current address</span>
              <div class="row">
                <div class="col-md-6 mt-5">
                  <label class="control-label">Address Line1</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="perstreet1" [value]="perstreet1" name="perstreet1"
                    class="form-control fields-required" data-attr="Permanent Address 1">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Address Line2</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="perstreet2" [value]="perstreet2" name="perstreet2"
                    class="form-control fields-required" data-attr="Permanent Address 2">
                </div>
                <div class="col-md-6 mt-5" *ngIf="country_code == '784'">
                  <label class="control-label">Emirates</label><span class="text-danger ml-2"> *</span>
                  <select [(ngModel)]="percity" name="percity" class="form-control fields-required"
                    data-attr="Permanent Emirates">
                    <option value="" selected="" disabled="">--Select Emirates--</option>
                    <option value="ABU DHABI">ABU DHABI</option>
                    <option value="DUBAI">DUBAI</option>
                    <option value="SHARJAH">SHARJAH</option>
                    <option value="AJMAN">AJMAN</option>
                    <option value="RAS AL KHAIMAH">RAS AL KHAIMAH</option>
                    <option value="FUJAIRAH">FUJAIRAH</option>
                    <option value="UMM AL QUWAIN">UMM AL QUWAIN</option>
                  </select>
                </div>
                <div class="col-md-6 mt-5" *ngIf="country_code == '634'" style="display: none;">
                  <label class="control-label">Emirates</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="percity" name="percity" class="form-control fields-required"
                    data-attr="Permanent Emirates">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">State</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="perstate" [value]="perstate" name="perstate" class="form-control fields-required"
                    data-attr="Permanent State">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">PO Box</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="perpobox" [value]="perpobox" name="perpobox" class="form-control fields-required"
                    data-attr="Permanent PO Box">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Country</label><span class="text-danger ml-2"> *</span>
                  <select [(ngModel)]="percountry" name="percountry" id="percountry"
                    class="form-control fields-required" data-attr="Permanent Country">
                    <option value="{{country_name}}">{{country_name}}</option>
                  </select>
                </div>
              </div>
              <br />
              <h6 class="font-weight-semibold text-uppercase mt-5">Nominee Details</h6>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">Relationship with Nominee</label><span class="text-danger ml-2"> *</span>
                  <select [(ngModel)]="relswithnomineeselected" name="relswithnomineeselected" required="true"
                    class="form-control fields-required" data-attr="Relationship With Nominee">
                    <option *ngFor="let nominee of relswithnomi" [value]="nominee.value">{{nominee.title}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-6">
                  <label class="control-label">First Name</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="newGiftcard.relsfirstname" name="relsfirstname" required="true"
                    class="form-control fields-required" data-attr="Nominee First Name">
                </div>
                <div class="col-md-6">
                  <label class="control-label">Last Name</label>
                  <input [(ngModel)]="newGiftcard.relslastname" name="relslastname" class="form-control">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Mobile</label>
                  <input [(ngModel)]="newGiftcard.relsmobile" name="relsmobile" class="form-control">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Email</label>
                  <input [(ngModel)]="newGiftcard.relsemail" name="relsemail" class="form-control">
                </div>
              </div>
              <br />
              <h6 class="font-weight-semibold text-uppercase mt-5">Nominee Address</h6>
              <hr>
              <label class="switch">
                <input type="checkbox" id="nomcheck" [checked]="isSaveCurPermanent"
                  (change)="setSaveCurNominee( $event )">
                <span class="slider round"></span>
              </label><span class=" ml-4">Same as current address</span>
              <div class="row">
                <div class="col-md-6 mt-5">
                  <label class="control-label">Address Line1</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="nomstreet1" [value]="nomstreet1" name="nomstreet1"
                    class="form-control fields-required" data-attr="Nominee Address 1">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Address Line2</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="nomstreet2" [value]="nomstreet2" name="nomstreet2"
                    class="form-control fields-required" data-attr="Nominee Address 2">
                </div>
                <div class="col-md-6 mt-5" *ngIf="country_code == '784'">
                  <label class="control-label">Emirates</label><span class="text-danger ml-2"> *</span>
                  <select [(ngModel)]="nomcity" name="nomcity" class="form-control fields-required"
                    data-attr="Nominee Emirates">
                    <option value="" selected="" disabled="">--Select Emirates--</option>
                    <option value="ABU DHABI">ABU DHABI</option>
                    <option value="DUBAI">DUBAI</option>
                    <option value="SHARJAH">SHARJAH</option>
                    <option value="AJMAN">AJMAN</option>
                    <option value="RAS AL KHAIMAH">RAS AL KHAIMAH</option>
                    <option value="FUJAIRAH">FUJAIRAH</option>
                    <option value="UMM AL QUWAIN">UMM AL QUWAIN</option>
                  </select>
                </div>
                <div class="col-md-6 mt-5" *ngIf="country_code == '634'" style="display: none;">
                  <label class="control-label">Emirates</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="nomcity" name="nomcity" class="form-control fields-required"
                    data-attr="Nominee Emirates">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">State</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="nomstate" [value]="nomstate" name="nomstate" class="form-control fields-required"
                    data-attr="Nominee State">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">PO Box</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="nompobox" [value]="nompobox" name="nompobox" class="form-control fields-required"
                    data-attr="Nominee PO Box">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Country</label><span class="text-danger ml-2"> *</span>
                  <select [(ngModel)]="nomcountry" name="nomcountry" id="nomcountry"
                    class="form-control fields-required" data-attr="Nominee Country">
                    <option value="{{country_name}}">{{country_name}}</option>
                  </select>
                </div>
              </div>



              <br />
              <h6 class="font-weight-semibold text-uppercase mt-5">ID Proof Upload</h6>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">ID Proof Type</label><span class="text-danger ml-2"> *</span>
                  <div style="font-size:14px;" class="warningofidprfupload text-center"></div>
                  <select [(ngModel)]="idproofvalue" name="idproofvalue" required="true"
                    class="form-control fields-required" data-attr="ID Proof Type">
                    <!-- <option *ngFor="let idproof of idproofselection" [value]="idproof.value">{{idproof.title}}</option> -->
                    <option value="Emirates" *ngIf="country_code=='784'">Emirates ID</option>
                    <option value="Emirates" *ngIf="country_code=='634'">Qatar ID</option>
                    <option value="Emirates" *ngIf="country_code=='414'">Civil ID</option>
                    <option value="Emirates" *ngIf="country_code=='512'">Oman ID</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="frontfile" class="control-label">Add Front Page</label><span class="text-danger ml-2">
                    *</span>
                  <input type="file" id="frontfile" (change)="handleFileInputfront($event.target.files)" required="true"
                    class="form-control fields-required" data-attr="Front Page Proof">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Id Proof Number</label><span class="text-danger ml-2"> *</span>
                  <input [(ngModel)]="idproofid" [value]="idproofid" name="idproofid" required="true"
                    class="form-control fields-required" data-attr="ID Proof Number">
                </div>
                <div class="col-md-6 mt-5">
                  <label class="control-label">Add Back Page</label>
                  <input type="file" id="backfile" (change)="handleFileInputback($event.target.files)"
                    class="form-control">
                </div>
              </div>

              <br />
              <h6 class="font-weight-semibold text-uppercase mt-5">Initial Payment</h6>
              <hr>

              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">Mode Of Pay</label><span class="text-danger ml-2"> *</span>
                  <select required="true" [(ngModel)]="newGiftcard.modeofpay" name="modeofpay" id="payment_mode"
                    class="form-control fields-required" data-attr="Mode of Pay">
                    <option value="credit_card">Credit Card</option>
                    <option value="debit_card" >Debit Card</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label class="control-label">Payment Gateway</label><span class="text-danger ml-2"> *</span>
                  <select required="true" [(ngModel)]="newGiftcard.paymentchannel" name="paymentchannel"
                    id="payment_channel" class="form-control fields-required" data-attr="Payment Gateway">
                    <option value="cc_avenue" *ngIf="country_code== '784'">CC Avenue</option>
                    <option value="dohabank" *ngIf="country_code== '634' && newGiftcard.modeofpay == 'credit_card'">Doha Bank</option>
                    <option value="knet" *ngIf="country_code == '414'">Knet</option>
                    <!-- <option value="cybersource" *ngIf="country_code== '512' ">CyberSource</option> -->
                    <!-- <option value="cybersource" *ngIf="country_code== '414' ">CyberSource</option> -->
                    <option value="smart_pay" *ngIf="country_code == '512'">Smart Pay</option>
                    <option value="qpay" *ngIf="country_code == '634' && newGiftcard.modeofpay == 'debit_card' && mnumber =='97450716268'">Q Pay</option>
                    <option value="sioncc"
                      *ngIf="country_code == '784' && newGiftcard.modeofpay == 'credit_card' && newGiftcard.monthdenomamt < 1501 ">
                      Monthly Autodebit standing Instruction On Credit Card</option>
                  </select>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-6">
                  <a href="javascript:" id="tc_btn" data-toggle='modal' data-target='#tc-popup' class="d-none"></a>
                  <span class="text-danger mr-2"> *</span>
                  <div style="display: inline-block;" [ngStyle]='TPaymentStyle'>I accept the<a
                      style="cursor: pointer;color:red;margin-left: 5px;" data-toggle='modal'
                      data-target='#tc-popup'>Terms and Conditions </a></div>
                </div>
              </div>
              <br />

              <button [ngStyle]='BPaymentStyle' mat-raised-button style="background-color: red;color: white;"
                type="submit">Pay & Register</button>
              <br />
              <br />
              <br />