<app-kalyan-navbar></app-kalyan-navbar>
<br/>
<br/>
<mat-card>
  <div class="bg-default-1 pt-5">
    
    <div class="container" style="margin-left:auto;">    
          <!-- <mat-button-toggle-group style="border-color:  coral ;" name="fontStyle" aria-label="Font Style">
        <mat-button-toggle style="background-color: #C0C0C0;border-color:  coral ; " value="home"><a style="color:red;" routerLink="/home">Home</a></mat-button-toggle>
        <mat-button-toggle style="background-color: #F0F0F0;border-color:  coral ;" value="new_giftcard"><a>New Giftcard</a></mat-button-toggle>
        <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="registered_giftcard"><a style="color:red;" routerLink="/giftcardregistered">Registerd Giftcard</a></mat-button-toggle>
        <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="payment_history"><a style="color:red;" href="#">Payment History</a></mat-button-toggle>
        <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="profile"><a style="color:red;" href="#">Profile</a></mat-button-toggle>
        <mat-button-toggle style="background-color: #C0C0C0 ;border-color:  coral ;" value="documents"><a style="color:red;" href="#">Documents</a></mat-button-toggle>
          </mat-button-toggle-group> -->
          <br/>  
          <mat-card>
            <p class="font-weight-semibold text-uppercase">Gift Card Information</p>
            <hr>
            <div class="row mt-2">
              <div class="col-md-2">Card ID</div>
              <div class="col-md-4 text-primary">{{cardid}}</div>
              
              <div [ngStyle]="MrStyle" class="col-md-3">Total Amount</div>
              <div class="col-md-3 text-primary">{{currency}} {{totalamount}}</div>
            </div>
            <br [ngStyle]="BrStyle"/>
            <div class="row mt-2">
              <div class="col-md-2">Registered Date</div>
              <div class="col-md-4 text-primary">{{regisdate | date: 'dd-MM-yyyy'}}</div>
              
              <div [ngStyle]="MrStyle" class="col-md-3">Monthly Reload Amount</div>
              <div class="col-md-3 text-primary">{{currency}} {{denomamount}}</div>
            </div>
            <br [ngStyle]="BrStyle"/>
            <div class="row mt-2">
              <div class="col-md-2">Paid Amount</div>
              <div class="col-md-4 text-primary">{{currency}} {{paidamount}}</div>
              
              <div [ngStyle]="MrStyle" class="col-md-3">No.of paid Denomination</div>
              <div class="col-md-3 text-primary">{{nopaiddenom}}</div>
            </div>
            <br [ngStyle]="BrStyle"/>
            <div class="row mt-2">
              <div class="col-md-2">Pending Amount</div>
              <div class="col-md-4 text-primary">{{currency}} {{pendingamount}}</div>
              
              <div [ngStyle]="MrStyle" class="col-md-3">Maturity Date</div>
              <div class="col-md-3 text-primary">{{maturity_date | date: 'dd-MM-yyy'}}</div>
            </div>
            <br/>
            <p class="font-weight-semibold text-uppercase">Customer Information</p>
            <hr>
            <div class="row">
              <div class="col-md-2">Customer</div>
              <div class="col-md-4 text-primary">{{cusname}}</div>
              
              <div [ngStyle]="MrStyle" class="col-md-3">Customer ID</div>
              <div class="col-md-3 text-primary">{{cusid}}</div>
            </div>
            <br [ngStyle]="BrStyle"/>
            <div class="row">
              <div class="col-md-2">Mobile</div>
              <div class="col-md-4 text-primary">{{mnumber}}</div>

              <div class="col-md-3"></div>
              <div class="col-md-3"></div>
            </div>
            <!-- <button  mat-raised-button (click)="backaction()"  style="background-color: red;color: white;float:left;">Back</button> -->
            <br>
            <span *ngIf="si_status == 'si_opted'"><label class="text-danger mr-1">* </label><label class="control-label mr-2">Note : </label><span class="text-danger">SI auto debit is enabled for this account.</span><br/><br/></span>

            <p class="text-uppercase font-weight-semibold">Payment Information</p>
              <hr>
            <div class="row">
              <div class="col-md-2">
                Mode of Pay
              </div>
              <div class="col-lg-4">
                <select [(ngModel)]="topupmodeofpay" name="topupmodeofpay" id="modeofpay" [required]="true" class="form-control font-weight-normal" style="color:black">
                  <option value="" class="font-weight-normal" disabled>-- Choose Mode Of Pay --</option>
                  <option value="credit_card" class="font-weight-normal">Credit Card</option>
                  <option value="debit_card" class="font-weight-normal" >Debit Card</option>
                  <!-- <option value="si_on_cc" class="font-weight-normal" *ngIf="country_code == '784'">SI on Credit Card</option> -->
                  <!-- <option value="option">Online Payment</option> -->
                </select>
              </div>
              <div class="col-md-2 pay_gateway" >
                Payment Gateway
              </div>
              <div class="col-lg-4 pay_gateway" >
                <select [(ngModel)]="topuppaymentchannel" name="topuppaymentchannel" id="paygateway" [required]="true" class="form-control font-weight-normal" style="color:black">
                  <option value="" class="font-weight-normal" disabled>-- Choose Payment Gateway -- </option>
                  <option value="cc_avenue" class="font-weight-normal" *ngIf="country_code == '784'">CC Avenue</option>
                  <option value="knet" *ngIf="country_code == '414'">Knet</option>
                  <option value="dohabank" *ngIf="country_code == '634' && topupmodeofpay =='credit_card'">Doha Bank</option>
                  <option value="smart_pay" *ngIf="country_code == '512'">Smart Pay</option>
                  <option value="qpay" *ngIf="country_code == '634' && topupmodeofpay =='debit_card' && mnumber == '97450716268'">Q Pay</option>
                  <option value="sioncc" *ngIf="country_code == '784' && topupmodeofpay =='credit_card' && si_status != 'si_opted' && denomamount < 1501">Monthly Autodebit standing Instruction On Credit Card</option>
                </select>
              </div>
            </div>
            <div class="row mt-5" style="display: none">
              <div class="col-md-12">
                <div >Apply Coupon 
                  <mat-slide-toggle (change)="setCouponApply( $event )"></mat-slide-toggle>
                  </div>
              </div>
            </div>
            <br/>
            
            <div [ngStyle]='couponStyle'>
              <p class="font-weight-semibold text-uppercase">Coupon Details</p>
              <div class="row">
                <div class="col-md-2">Coupon type</div>
                <div class="col-md-4">
                  <select class="form-control" [ngStyle]='FieldStyle'>
                    <option value="option">Coupon1</option>
                    <option value="option">Coupon2</option>
                  </select>
                </div>
                <div class="col-md-2">Coupon Code</div>
                <div class="col-md-4">
                  <input type="text" name="" [(ngModel)]="coupon_code" [value]="coupon_code" required="true" class="form-control">
                </div>
      
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <button  class="btn btn-sm btn-primary" (click)="backaction()"><i class="fa fa-backward mr-2"></i>Back</button>
              <button class="btn btn-sm btn-danger float-right" (click)="giftcardpaymentpreview({'denomination_amount':denomamount,'topup_id':cardid,'payment_channel':topuppaymentchannel,'payment_mode':topupmodeofpay,'mobile_no':mnumber})" [disabled]="isPayandredeem">Pay</button>
            </div>
          </div>
          <br/>
          <br/>
            
      <div class="modal modal-dialog-centered fade" tabindex="-1" role="dialog" aria-labelledby="modelTitle" aria-hidden="true"
              [ngClass]="{
                  'show': registerWarningClass == true,
                  '': registerWarningClass == false
                  }" 
              [ngStyle]='registerWarningStyle'
              style="color:gray;opacity:0.98;display:none;margin:auto;"
              id="modal-register-warning"
              >
              <!-- style="margin-top: 150px" -->
                  <div class="modal-dialog modal-dialog-centered"  role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h4 class="modal-title" id="modelTitle">Warning</h4>
                              <button type="button" (click)="hideWarning()" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                          </div>
                          <div class="modal-body">
                              <div *ngFor="let wc of warning_content"><span>{{wc}}</span><br/></div>
                          </div>
                          <div class="modal-footer">
                              <button type="button" (click)="hideWarning()" class="btn btn-danger">Close</button>
                          </div>
                      </div>
                  </div>
              </div>
          </mat-card>
    </div>
  </div>
</mat-card>


