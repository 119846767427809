import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { LoginComponent } from './login/login.component';
import { MessageComponent } from './message/message.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { JsonpModule } from '@angular/http';

import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';



import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './home/home.component';
import { GiftcardComponent } from './giftcard/giftcard.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import { RegistredgiftcardComponent } from './registredgiftcard/registredgiftcard.component';
import { MatNativeDateModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GiftcardlistComponent } from './giftcardlist/giftcardlist.component';
import { GiftcardtopupComponent } from './giftcardtopup/giftcardtopup.component';
import { PaymentpreviewComponent } from './paymentpreview/paymentpreview.component';
import { PaymenthistoryComponent } from './paymenthistory/paymenthistory.component';
import { ProfileComponent } from './profile/profile.component';
import { ProceedpaymentComponent } from './proceedpayment/proceedpayment.component';
import { PaymentresponseComponent } from './paymentresponse/paymentresponse.component';
import { PaymentDohaBankComponent } from './payment-doha-bank/payment-doha-bank.component';
import { PaymentCyberSourceComponentComponent } from './payment-cyber-source-component/payment-cyber-source-component.component';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { KalyanNavbarComponent } from './kalyan-navbar/kalyan-navbar.component';
import {DataTablesModule} from 'angular-datatables';
import { SmartPayGatewayComponent } from './smart-pay-gateway/smart-pay-gateway.component';
import { QpayGatewayComponent } from './qpay-gateway/qpay-gateway.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { HomeNavbarComponent } from './home-navbar/home-navbar.component';
import { NoDataScreenComponent } from './no-data-screen/no-data-screen.component';
import { StandingInstructionComponent } from './standing-instruction/standing-instruction.component';
// import { KnetComponent } from './knet/knet.component';
import { PayItComponent } from './pay-it/pay-it.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { PayitpaymentpreviewComponent } from './payitpaymentpreview/payitpaymentpreview.component';
// import { ToastrModule } from 'ngx-toastr';
import { QpaypaymentpreviewComponent } from './qpaypaymentpreview/qpaypaymentpreview.component';





@NgModule({
  declarations: [
    AppNavbarComponent,
    AppComponent,
    LoginComponent,
    MessageComponent,
    SignupComponent,
    HomeComponent,
    GiftcardComponent,
    RegistredgiftcardComponent,
    GiftcardlistComponent,
    GiftcardtopupComponent,
    PaymentpreviewComponent,
    PaymenthistoryComponent,
    ProfileComponent,
    ProceedpaymentComponent,
    PaymentresponseComponent,
    PaymentDohaBankComponent,
    PaymentCyberSourceComponentComponent,
    KalyanNavbarComponent,
    SmartPayGatewayComponent,
    QpayGatewayComponent,
    TermsAndConditionsComponent,
    HomeNavbarComponent,
    NoDataScreenComponent,
    StandingInstructionComponent,
    PayItComponent,
    QpaypaymentpreviewComponent
  ],
  entryComponents: [
    LoginComponent,
    MessageComponent,
    SignupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCarouselModule.forRoot(),
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule,
    MatTableModule,
    JsonpModule,
    NgxSpinnerModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSidenavModule,
    MatExpansionModule,
    DataTablesModule,
    NgbModule, 
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
